@tailwind base;
@tailwind components;
@tailwind utilities;

.css-822hb5-MuiList-root{
    padding: 0 10px !important;
}
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root{
    border: 1px solid gray !important;
    width: 270px !important;
    border-radius: 8px !important;
    height: 40px !important;
}
.css-tlelie-MuiListItemText-root{
    text-align: center !important;
}
@media screen and (max-width:575px) {
    .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
        width: 130px !important;
    }
    
}